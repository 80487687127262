<template>
  <div
    :style="{
      background: `url(${require(`@/assets/images/bg.png`)})`,
    }"
    class="landing__container d-flex flex-column align-items-center py-5"
  >
    <!-- Org logo  -->
    <a
      href="http://getboarded.com/"
      class="logo d-flex align-items-center justify-content-center"
    >
      <img
        class="w-100"
        :src="require(`@/assets/images/organization/default/logo.png`)"
        alt="Ale"
      />
    </a>

    <div
      class="landing__container__text d-flex flex-column align-items-center justify-content-center"
    >
      <h2 class="landing__container__text--main-heading">
        Your Library is not yet active!
      </h2>

      <div>
        <h4 class="px-2 landing__container__text--sub-heading mt-2">
          How to activate Library:
        </h4>

        <ul class="px-2 mt-75 mt-md-2">
          <li class="subheading d-flex">
            <span>
              Enter your behavioral abilities and rank them in order of
              priority.
            </span>
          </li>
          <li class="subheading d-flex">
            <span>
              Enter your functional skills and check the "add to priority" box .
            </span>
          </li>
          <li class="subheading d-flex">
            <span>
              Activate the BOT with your preferences to get personalized
              recommendation. (Optional)
            </span>
          </li>
        </ul>
      </div>

      <div
        class="cursor-pointer custom-btn d-flex align-items-center justify-content-center mt-1"
        @click="routeToEditor"
      >
        <p class="m-0 custom-btn-text">Activate Library</p>
      </div>
    </div>

    <!-- <div class="social__icons d-flex align-items-center justify-content-center">
      <a href="https://www.instagram.com/getboarded/" target="_blank">
        <img
          class="icon mx-2 cursor-pointer"
          src="@/assets/images/landing/insta.svg"
          alt="Instagram"
      /></a>
      <a href="https://twitter.com/gtbrdd" target="_blank">
        <img
          class="icon cursor-pointer"
          src="@/assets/images/landing/twitter.svg"
          alt="Twitter"
      /></a>
    </div> -->
  </div>
</template>
<script>
import constants from "@/constants";
export default {
  name: "NoSkill",
  data() {
    return {
      constants,
    };
  },
  computed: {
    authDetails() {
      return this.$store.state.auth;
    },
  },
  methods: {
    routeToEditor() {
      const { org, gbuid } = this.$route.query;
      window.location.href = `${constants.editorUrl}/oauth-callback?organization=${org}&token=${this.authDetails.accessToken}&userId=${gbuid}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@mixin transparent-text {
  background-image: -webkit-linear-gradient(0deg, #f7f7f793 10%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.landing {
  &__container {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 100vh;
    &__text {
      margin-top: 6em;
      font-family: "Inter", sans-serif;
      &--main-heading {
        font-size: 5em;
        font-weight: 900;
        @include transparent-text;
      }
      &--sub-heading {
        width: 75%;
        font-size: 2.25em;
        font-weight: 700;
        @include transparent-text;
      }
    }

    ul {
      list-style: none;
      li {
        position: relative;
        margin: 1em 0;
        &::before {
          display: inline-block;
          position: absolute;
          top: 10px;
          content: "";
          width: 0.75em;
          height: 0.75em;
          background: radial-gradient(
              18.95% 15.16% at 29.81% 21.98%,
              rgba(255, 255, 255, 0.6) 0%,
              rgba(255, 255, 255, 0) 100%
            )
            #f7fbfd;
          box-shadow: inset 0px 2.80596px 8.41789px rgba(0, 0, 0, 0.34);
          border-radius: 50%;
          margin-right: 0.75rem;
        }
      }
      span {
        position: relative;
        @include transparent-text;
        margin-left: 1.5em;
        font-size: 1.25em;
        font-weight: 500;
      }
    }

    .custom-btn {
      background: #222222;
    }

    .logo {
      width: 5%;
    }

    .social__icons {
      .icon {
        width: 3.5em;
        height: 3.5em;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .landing {
    &__container {
      &__text {
        margin-top: 3em;
        &--main-heading {
          font-size: 3.5em;
        }
        &--sub-heading {
          font-size: 2em;
        }
      }

      .logo {
        width: 10%;
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  .landing {
    &__container {
      &__text {
        margin-top: 1em;
        &--main-heading {
          text-align: center;
        }
        &--sub-heading {
          font-size: 1.75em;
          width: 95%;
        }
      }

      .logo {
        width: 15%;
      }
    }
  }
}
</style>
